function loginInitPage1() {
  var rvnMenu = $("#navbar").rvnm({
    // mode: 'mobile',
    // responsive: false,
    searchable: false,
    theme: "dark-lesb",
  });
  console.log(rvnMenu);
  //  rvnMenu.setMode('minimal');
  rvnMenu.setTheme("dark-ruby");
}

function loginInitPage2() {
  $(".mobiletable").on("click", function () {
    $(this).toggleClass("top10mobHide");
  });
  $(".closeBtn").click(function () {
    $(".cookiePolicy").hide();
  });
  $(".closeTap a").click(function () {
    $(".notificationInnerBlock").slideToggle("slow");
    $(".closeTap a i").toggleClass("rotate");
  });
  // nick
  // $(".tgAction").click(function () {
  //   if ($(this).hasClass("expanded")) {
  //     $(".tgActionText").css({ height: "74px" });
  //     $(this).removeClass("expanded");
  //   } else {
  //     $(".tgActionText").css({ height: "auto" });
  //     $(this).addClass("expanded");
  //   }
  //   $(".tgAction i").toggleClass("rotate");
  // });
}

function loginInitPage3() {
  var $backToTopEle = $(".toTop"),
    $backToTopFun = function () {
      var st = $(document).scrollTop(),
        winh = $(window).height();
      st > 200 ? $backToTopEle.fadeIn("slow") : $backToTopEle.fadeOut("slow");
      //IE6下的定位
      if (!window.XMLHttpRequest) {
        $backToTopEle.css("top", st + winh - 166);
      }
    };
  $(".toTop").click(function () {
    $("html, body").animate({ scrollTop: 0 }, 1200);
  });
  $backToTopEle.hide();
  $backToTopFun();
  $(window).bind("scroll", $backToTopFun);
  $("#catalogWord").click(function () {
    $("#catalog").slideToggle(600);
  });
}

// James Pang--
// $(function () {
//   $(".datepicker").datepicker({
//     //年份可以選擇
//     changeYear: true,
//     //月份可以選擇
//     changeMonth: true,
//     //設定 下拉式選單月份 在 年份的後面
//     showMonthAfterYear: true,
//     //設定月份縮寫
//     monthNamesShort: [
//       "1",
//       "2",
//       "3",
//       "4",
//       "5",
//       "6",
//       "7",
//       "8",
//       "9",
//       "10",
//       "11",
//       "12",
//     ],
//     dateFormat: "yy/mm/dd",
//   });
// });

$(function () {
  var rvnMenu = $("#navbar").rvnm({
    // mode: 'mobile',
    // responsive: false,
    searchable: false,
    theme: "dark-lesb",
  });
  console.log(rvnMenu);
  //  rvnMenu.setMode('minimal');
  rvnMenu.setTheme("dark-ruby");
});

$(document).ready(function () {
  $(".mobiletable").on("click", function () {
    $(this).toggleClass("top10mobHide");
  });
  $(".closeBtn").click(function () {
    $(".cookiePolicy").hide();
  });
  $(".closeTap a").click(function () {
    $(".notificationInnerBlock").slideToggle("slow");
    $(".closeTap a i").toggleClass("rotate");
  });
  // $(".tgAction").click(function () {
  //   if ($(this).hasClass("expanded")) {
  //     $(".tgActionText").css({ height: "74px" });
  //     $(this).removeClass("expanded");
  //   } else {
  //     $(".tgActionText").css({ height: "auto" });
  //     $(this).addClass("expanded");
  //   }

  //   $(".tgAction i").toggleClass("rotate");
  // });
});

$(function () {
  var $backToTopEle = $(".toTop"),
    $backToTopFun = function () {
      var st = $(document).scrollTop(),
        winh = $(window).height();
      st > 200 ? $backToTopEle.fadeIn("slow") : $backToTopEle.fadeOut("slow");
      //IE6下的定位
      if (!window.XMLHttpRequest) {
        $backToTopEle.css("top", st + winh - 166);
      }
    };
  $(".toTop").click(function () {
    $("html, body").animate({ scrollTop: 0 }, 1200);
  });
  $backToTopEle.hide();
  $backToTopFun();
  $(window).bind("scroll", $backToTopFun);
  $("#catalogWord").click(function () {
    $("#catalog").slideToggle(600);
  });
});

$(document).ready(function () {
  $(".add-fund1").click(function () {
    $(".add-content1").slideToggle("slow");
  });
});

$(document).ready(function () {
  $(".add-fund1-1").click(function () {
    $(".add-content1-1").slideToggle("slow");
  });
});

$(document).ready(function () {
  $(".add-fund2").click(function () {
    $(".add-content2").slideToggle("slow");
  });
});

$(document).ready(function () {
  $(".add-fund2-1").click(function () {
    $(".add-content2-1").slideToggle("slow");
  });
});

$(document).ready(function () {
  $(".add-fund3").click(function () {
    $(".add-content3").slideToggle("slow");
  });
});

$(document).ready(function () {
  $(".add-fund3-1").click(function () {
    $(".add-content3-1").slideToggle("slow");
  });
});
